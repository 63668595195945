.table thead th {
    width: 10% !important;
}

.disable {
    background-color: #bfbfbf !important;
}

.option {
    cursor: pointer;

    &:hover {
        color: var(--primary_color) !important;
    }
}