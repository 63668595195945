@import "../../assets/theme/vars";

.wrapper_control {
  @include bggrey;
  min-height: calc(100vh - 4rem);
}

.input_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .drop_color {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 5px;
    margin-right: .4rem;
  }
  .form-group{
    width: 100%;
    height: 100px;
    margin-bottom: 0;
  }
}
