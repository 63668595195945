.wrapper_block {
  position: relative;

  .box_content_block {
    display: flex;
    align-self: center;
    align-items: center;
    height: 100vh;
  }

  &.modal {
    z-index: 1052 !important;

    .modal-content {
      background: transparent !important;
      box-shadow: none;
      border: 0;
    }

    .modal-dialog {
      max-width: 100%;
      max-height: 100%;
      margin: 0 !important;
    }
  }
}

#root ~ .fade.modal-backdrop.show {
  z-index: 1060; 
}