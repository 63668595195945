@import "../../assets/theme/vars";

.wrapper_users {
  @include bggrey;
  min-height: calc(100vh - 4rem);
  .box_actions {
    .btn {
      min-width: auto !important;
      padding: 0 1rem;
    }
    .dropdown-item a:hover {
      color: $white_text;
    }
    .btn-secondary {
      &:active,
      &:focus {
        background: var(--primary_color) !important;
      }
    }
  }
  .box_tool_user {
    .form_inline {
      display: flex;
      justify-content: flex-end;
      .form-control {
        border-radius: 5px 0 0 5px;
      }
    }
    .box_search_inp {
      .btn {
        background: #d1d6df;
        min-width: auto;
        height: calc(1.5em + 0.75rem + 2px);
        border-radius: 0 5px 5px 0;
        padding: 0.375rem 0.75rem;
      }
    }
  }
}

.wrapper_users_details {
  @include bggrey;
  min-height: calc(100vh - 4rem);
}

.control-checkbox {
  display: block;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white;
    border-radius: .25rem;
  }
  &:hover {
    .control-checkbox-input ~ .control-checkbox-checkmark {
      background-color: white;
    }
  }
}

.control-checkbox .control-checkbox-input:checked ~ .control-checkbox-checkmark {
  background-color: black;
}

.control-checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.control-checkbox .control-checkbox-input:checked ~ .control-checkbox-checkmark:after {
  display: block;
}

.control-checkbox .control-checkbox-checkmark:after {
  left: 9px;
  top: 3px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}