@import "../../../../assets/theme/vars";

.wrapper_purchased_item {
  background: #ffffff;
  padding: 0.25rem 0;
  margin-bottom: 1rem;
  border: 1px solid #dce3e8;
  border-radius: 8px;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);

  .head_purchased_item {
    padding: 0.5rem;
    cursor: pointer;

    .col_unicas {
      position: relative;
      display: flex;
      justify-content: space-between !important;
      width: 100%;
      padding-left: 2rem;

      .ico_chevron {
        position: absolute;
        left: 0;
        top: 10px;
        z-index: 1s;
        background: #ffffff;
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        min-width: 23px;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        border: 1px solid #cccccc;
        color: #666666;
        -webkit-transition: 0.5s cubic-bezier(0.2, 0.61, 0.41, 0.95);
        transition: 0.5s cubic-bezier(0.2, 0.61, 0.41, 0.95);

        &.open {
          transform: rotate(-180deg);
        }
      }
    }

    .ttl_info {
      font-family: "is_medium";
      margin-bottom: 0.25rem;
    }

    .p_info {
      margin: 0;
      font-size: 0.9rem;
      font-weight: 300;

      .paid {
        color: var(--primary_color);
        font-family: "is_medium";
      }

      .pending_payment {
        background: var(--primary_color);
        padding: 0.25rem 1rem;
        color: #ffffff;
        display: inline-block;
        border-radius: 0.25rem;
      }
    }
  }
}