@import "../../assets/theme/vars";

.wrapper_box_user_profile {
    background: var(--primary_color);
    height: 100%;
    border-radius: $border_radius;
    color: $white_text;
    font-family: "is_medium";
    padding: 1rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        content: "";
        border-radius: $border_radius;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40%;
        background: rgba(0, 0, 0, 0);
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
        background: -webkit-gradient( left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.15)) );
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
    }

    * {
        z-index: 1;
    }

    .ico_tool {
        position: absolute;
        font-size: 1.5rem;
        top: 0.5rem;
        right: 0.75rem;
        color: $white_text;
    }

    .box_avatar_user {
        .avatar {
            width: 100px;
            height: 100px;
            margin: 0 auto;
        }
    }

    .box_info_user {
        margin-top: .50rem;
    }

    .box_data_user {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 1rem;
        position: relative;
        z-index: 9;

        & > div {
            width: 100%;
            margin-right: 0.75rem;

            &:last-child {
                margin: 0;
            }

            h6 {
                margin-bottom: 0.25rem;
                font-size: 0.85rem;
            }

            p {
                background: $white_text;
                color: $black;
                font-size: 0.85rem;
                padding: 0.5rem 0.15rem;
                border-radius: 6px;
            }
        }
    }

    .box_insignias {
        img {
            height: 25px;
            margin: 0 .25rem;
        }
    }
}
