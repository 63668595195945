@import "../../assets/theme/vars";

.wrapper_login {
    background: $bg_grey;
    height: 100vh;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    .content_login {
        background: #fff;
        max-width: 1024px;
        width: 100%;
        height: 80vh;
        min-height: 650px;
        border-radius: 8px;
        box-shadow: -3px -3px 40px rgba(45, 45, 46, 0.15);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .btn.primary.default {
            background: var(--primary_color);
        }

        .box_msj {
            background: var(--primary_color);
            color: $white_text;
            height: 100%;
            border-radius: 8px 0 0 8px;
            width: 35%;
            min-width: 200px;
            padding: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            position: relative;
            z-index: 9;

            & > div {
                position: relative;
                z-index: 1;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 50%;
                border-radius: 8px;
                background: rgba(0, 0, 0, 0);
                background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
                background: -webkit-gradient( left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.15)) );
                background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
                background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
                background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
            }
        }

        .box_form {
            width: 65%;
            height: 100%;
            padding: 1rem;
            display: flex;
            justify-content: center;
            text-align: center;

            .box_logo_company_login {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 3rem;
                margin-top: 5rem;

                img {
                    width: 70%;
                }
            }

            .box_inputs {
                max-width: 350px;
                margin: 0 auto;

                .box_input_icon {
                    .ico_show_pass {
                        position: absolute;
                        width: 27px;
                        height: 27px;
                        border-radius: 50%;
                        top: 9px;
                        right: 7px;
                        color: #666;
                        padding-top: 3px;
                        cursor: pointer;

                        &:hover {
                            background: #dcdcdc;
                        }

                        .fas {
                            font-size: 13px;
                        }
                    }
                }
            }

            .ttl_form {
                color: var(--primary_color);
                margin-bottom: 2rem;
            }

            .btn_links {
                font-size: 0.95rem;
                cursor: pointer;
            }
        }
    }
}
