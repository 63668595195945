@import "../../../assets/theme/vars";

.wrapper_shoppingHistory {
	@include bggrey;
	min-height: calc(100vh - 4rem);

	.contain_shop_history {
		.boxes_hop_history {
			border-radius: 8px;
			padding: 1rem;
			padding-top: 0;

			.paginated_page {
				text-align: end;

				button {
					min-width: 36px;
					border: 10px;
					box-sizing: border-box;
					display: inline-block;
					-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
					cursor: pointer;
					text-decoration: none;
					margin: 0px;
					padding: 0px;
					outline: none;
					font-size: inherit;
					font-weight: inherit;
					position: relative;
					height: 36px;
					line-height: 36px;
					min-width: 36px;
					color: rgba(0, 0, 0, 0.87);
					transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
					border-radius: 2px;
					user-select: none;
					overflow: hidden;
					background-color: rgba(0, 0, 0, 0);
					text-align: center;
					border-radius: 45px;

					&.isActive {
						color: var(--primary_color) !important;
					}

					&:hover {
						background-color: rgba(153, 153, 153, 0.2);
					}
				}
			}
		}
	}
}

.dateFilter {
	button {
		width: 1rem;
		height: 1.8rem;
		transition-duration: 0.3s;

		&:disabled {
			border-color: #cccccc;

			p {
				color: #cccccc;
			}
		}
	}

	p {
		margin-top: -0.5rem;
		transition-duration: 0.3s;
	}

	.date-control {
		float: left;
		width: 15rem;
		margin-right: 1rem;
	}

	.button-control {
		background-color: var(--primary_color);
		border-radius: 5px;
		outline: none;
		color: white;
		float: left;
		width: 8rem;
		height: 1.8rem;
		margin-right: 1rem;

		&:hover {
			opacity: 0.8;
		}

		&:disabled {
			background-color: #cccccc;
		}
	}

	height: 4rem;
}

@media only screen and (max-width: 767px) {
	.dateFilter {
		button {
			width: 1rem;
			height: 1.8rem;

			&:disabled {
				border-color: #cccccc;

				p {
					color: #cccccc;
				}
			}
		}

		p {
			margin-top: -0.5rem;
		}

		.date-control {
			float: left;
			width: 15rem;
			margin-right: 1rem;
			margin-bottom: 0.5rem;
		}

		.button-control {
			background-color: var(--primary_color);
			border-radius: 5px;
			outline: none;
			color: white;
			float: left;
			width: 8rem;
			height: 1.8rem;
			margin-bottom: 0.5rem;

			&:hover {
				opacity: 0.8;
			}

			&:disabled {
				background-color: #cccccc;
			}
		}

		height: 4rem;
		margin-bottom: 5rem;
	}
}