@import "../../assets/theme/vars";

.box_container_hub {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-sizing: content-box;
  .item_provaider_hub {
    width: 20%;
    height: 100%;
    padding: 0.5rem 0.75rem;
    &:hover .item_content{
      box-shadow: 0px 0px 0 2px var(--primary_color);
      color: $black;
    }
    .item_content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.05);
      background: #ffffff;
      border-radius: 1rem;
      text-align: center;
      padding: 0.5rem;
      margin: 0;
      position: relative;
      .box_p_img {
        min-height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: .25rem;
        img {
          width: 90%;
          transition: transform 0.3s;
        }
      }
    }
  }
}
